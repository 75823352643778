<template>
  <div>
    <v-card>
      <v-card-title>Send Notification</v-card-title>

      <v-container>
        <v-form ref="form" >
         
            <v-container>
              <v-select
                :items="[ { name: 'Push Notification', type: 'PUSH' }, { name: 'E-mail', type: 'EMAIL' } ]"
                v-model="notifyType"
                item-text="name"
                item-value="type"
                label="Notification Type"
              />

              <v-col cols="12">
                <v-radio-group v-model="audienceType" 
                  row
                  :rules="[v => !!v || 'Type is required']"
                >
                  <v-radio label="Send to respondent(s)" value="1"></v-radio>
                  <v-radio label="Send to all the respondents in a project" value="2"></v-radio>
                  <v-radio label="Send to respondents having activities in active state" value="3"></v-radio>
                  <v-radio label="Send to respondents in selected country" value="4"></v-radio>
                </v-radio-group>
              </v-col>

              <v-autocomplete
                :items="users"
                label="Select User Id`s"
                deletable-chips
                item-text="_id"
                item-value="_id"
                chips
                persistent-hint
                hint="Select the id`s you want to send notification to"
                v-if="audienceType == '1'"
                multiple
                v-model="userIds"
                :rules="[v => !!v && v.length > 0 || 'UserIds is required']"
              ></v-autocomplete>

              <v-select
                v-if="audienceType == '2'"
                label="Project Name"
                :items="projects"
                item-text="name"
                item-value="_id"
                v-model="projectId"
                :rules="[v => !!v || 'ProjectId is required']"
              ></v-select>

              <v-select
                v-if="audienceType == '3'"
                :items="projects"
                item-text="name"
                item-value="_id"
                label="Project"
                v-model="projectId"
                v-on:change="getActivity(projects._id)"
                :rules="[v => !!v || 'Project is required']"
              ></v-select>

              <v-autocomplete
                :items="activities"
                v-if="audienceType == '3'"
                item-text="name"
                item-value="_id"
                autocomplete
                v-model="activityId"
                label="Choose Activity"
                :rules="[v => !!v || 'Activity is required']"
              ></v-autocomplete>

              <v-autocomplete
                :items="countries"
                v-if="audienceType == '4'"
                autocomplete
                multiple
                v-model="selectedCountries"
                label="Choose Country"
                :rules="[v => !!v || 'Country is required']"
              ></v-autocomplete>
            </v-container>
       
          <v-divider></v-divider>

          <div v-if="notifyType == 'EMAIL'">
            <v-card-title>
              <span v-if="normalMode == true">Normal Mode 🙊</span>
              <span v-else>Pro Mode 🙈</span>

              <v-spacer />              
              <v-btn v-if="normalMode == true" v-on:click="normalMode = !normalMode" color="error">Bring me to Pro Mode 🕺</v-btn>
              <v-btn v-else v-on:click="normalMode = !normalMode" color="error">Bring me to Normal Mode 🧟</v-btn>
            </v-card-title>
            
            <v-container>
              <v-text-field
                v-model="emailData.subject"
                label="Subject"
                single-line
                :rules="[v => !!v || 'Subject is required']"
              ></v-text-field>

              <quill-editor v-if="normalMode"
                ref="myQuillEditor"
                v-model="emailData.body"
                :rules="[v => !!v && v.length > 0 || 'UserIds is required']"
              />

              <vue-json-editor class="mb-4"
                v-if="!normalMode"
                v-model="emailData"
                :show-btns="false"
                :expndedOnStart="false"
              ></vue-json-editor>
            </v-container>
          </div>

          <div v-if="notifyType == 'PUSH'">
            <v-card-title>
              <span v-if="normalMode == true">Normal Mode 🙊</span>
              <span v-else>Pro Mode 🙈</span>

              <v-spacer />              
              <v-btn v-if="normalMode == true" v-on:click="normalMode = !normalMode" color="error">Bring me to Pro Mode 🕺</v-btn>
              <v-btn v-else v-on:click="normalMode = !normalMode" color="error">Bring me to Normal Mode 🧟</v-btn>
            </v-card-title>

            <v-container>
              <div v-if="normalMode">
                <v-text-field
                  v-model="notificationData.notification.title_loc_args[0]"
                  label="Title"
                  single-line
                  :rules="[v => !!v || 'Title is required']"
                ></v-text-field>

                <v-textarea
                  v-model="notificationData.notification.body_loc_args[0]"
                  label="Description"
                  single-line
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>

                <v-text-field
                  v-model="notificationData.notification.url"
                  label="Website (URL) to Open on Notificiation Click"
                  single-line
                  :rules="[v => (v == null || v.startsWith('https://')) || 'URL must start with https://']"
                ></v-text-field>

                <v-text-field
                  v-model="notificationData.notification.image"
                  label="Notification Banner Image"
                  single-line
                  :rules="[v => (v == null || v.startsWith('https://')) || 'URL must start with https://']"
                ></v-text-field>
              </div>

              <vue-json-editor class="mb-4"
                v-if="!normalMode"
                v-model="notificationData"
                :show-btns="false"
                :expndedOnStart="false"
              ></vue-json-editor>

            </v-container>
          </div>

          <v-btn color="primary" class="mt-4" @click="processAndPushToUsers">Notify</v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import RestResource from "./../../services/dataServiceMobile.js";
import vueJsonEditor from "vue-json-editor";

const service = new RestResource();

export default {
  components: {
    vueJsonEditor
  },
  data() {
    return {
      notifyType: "PUSH",
      audienceType: null,
      normalMode: true,
      projects: [],
      users: [],
      userIds: [],
      projectId: null,
      activityId: null,
      activities: [],
      countries: [],
      selectedCountries: [],
      valid: true,
      emailData: {
        subject: null,
        body: null,
      },
      notificationData: {
        notification: {
          title_loc_key: "PLACEHOLDER_NOTIFICATION",
          title_loc_args: [],
          body_loc_key: "PLACEHOLDER_NOTIFICATION",
          body_loc_args: [],
          // title: null,
          // body: null,
          sound: "default",
          foreground: true,
          color: "#2ca8a0",
          tag: null,
          click_action: "PUSH_NOTIFICATION",
          image: null,
          url: null,
        },
        notificationType: "BY_PASS",
      },
    };
  },
  mounted() {
    this.fetchUserIds();
    this.loadProjects();
    this.fetchUserCountries();
  },
  methods: {
    fetchUserCountries() {
      service.fetchUsers({ getUniqueCountries: true }).then(r => {
        this.countries = r.data[0].countries;
      });
    },
    fetchUserIds() {
      service.fetchUserIds().then(r => {
        this.users = r.data;
      });
    },
    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    },
    getActivity() {
      service.fetchActivities({ projectId: this.projectId }).then(r => {
        this.activities = r.data;
      });
    },

    async processAndPushToUsers() {
      let userIds = []

      if (this.audienceType == "1") {
        userIds = this.userIds
      }

      if (this.audienceType == "2") {
        let data = await service.fetchActiveActivitiesUserIds({ projectId : this.projectId });
        userIds = data.data;
      }

      if (this.audienceType == "3") {
        let data = {
          projectId: this.projectId,
          activityId: this.activityId,
          status: "A",
        };

        let userIdsData = await service.fetchActiveActivitiesUserIds(data);
        userIds = userIdsData.data;
      }

      if (this.audienceType == "4") {
        let { data } = await service.fetchUsers({ getUserIdByMatchingCountries: true, countries: this.selectedCountries })
        userIds = data
      }

      if (this.notifyType == "PUSH") {
        this.notificationData.userIds = userIds
        this.pushToUsers(this.notificationData);        
      } else if (this.notifyType == "EMAIL") {        
        this.emailData.userIds = userIds
        this.emailToUsers(this.emailData)
      }
    },
    pushToUsers(notificationData) {
      if (this.$refs.form.validate()) {
        notificationData.notification.tag = Date.now()+Math.random().toString()

        if (confirm("Are you sure?")) {
          this.$setLoader();

          service.pushToDeviceByUserIds(notificationData).then(res => {
           if (this.normalMode) {
             this.notificationData.userIds = []
             this.notificationData.notification.title_loc_args = []
             this.notificationData.notification.body_loc_args = []
             this.notificationData.notification.url = null
             this.notificationData.notification.image = null
           }
 
           this.$showSuccessMessage(res.data)
         })
        }
      }
    },
    emailToUsers(emailData) {
      if (this.$refs.form.validate()) {
        if (confirm("Are you sure?")) {
          this.$setLoader(); 

          service.emailToUsers(emailData).then(res => {
            this.$showSuccessMessage(res.data)
          })
        }
      }
    }
  }
};
</script>

